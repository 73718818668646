<!--
FAQ
-->
<template>
  <div v-if="this.data.length > 0" class="container px-0">
      <div class="px-3 pb-2" v-for="(item, key) in (this.data)" :key="key">
        <div data-bs-toggle="collapse" @click="toggle(hash(item.question))" role="button" aria-expanded="false"
             class="py-1 px-3">
          <div class="text-blue fw-bold">
            <font-awesome-icon v-if="hash(item.question) == active" :icon="['fas', 'minus-circle']"/>
            <font-awesome-icon v-else :icon="['fas', 'plus-circle']"/>
            {{ item.question }}
          </div>
        </div>
        <div class="collapse" :class="hash(item.question) == active ? 'show' : 'hidden'">
          <div class="px-4 fs-6">
            <div class="col-12 py-1" v-html="item.answer"/>
          </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "module8",
  props: {
    data: {data: [Array]},
  },
  data() {
    return {
      active: null,
    }
  },
  methods: {
    toggle(index) {
      if (this.active === index) {
        this.active = false;
      } else {
        this.active = index;
      }
    },
    hash(string){
      return btoa(encodeURIComponent(string));
    }
  },
}
</script>

<style scoped>

</style>